import { theme } from "@chakra-ui/core";
import { css } from "@emotion/core";

// [container, breakpoint]
const sizes = {
    sm: ["520px", "576px"],
    md: ["720px", "768px"],
    lg: ["960px", "992px"],
    xl: ["1170px", "1200px"],
};

const breakpoints = Object.values(sizes).reduce((bp, size) => {
    bp.push(size[1]);
    return bp;
}, []);

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const EditorTheme = {
    ...theme,
    breakpoints,
    colors: {
        ...theme.colors,
        brand: {
            blue: "#140F64",
            red: "#E11414",
            redLight: "#db5858",
            paleRed: "#FDF1F2",
        },
    },
    fonts: {
        ...theme.fonts,
        heading: "Mulish, sans-serif",
        body: "Mulish, sans-serif",
    },
};

export const AdminTheme = {
    breakpoints: {
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        tablet: 768,
        laptop: 992,
        desktop: 1024,
        desktopL: 1700,
    },
};

// Global styles designs: link to the client's style guide if there is one.

const listStyles = css`
    ul {
        list-style: none;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
        position: relative;
        li {
            line-height: 30px;
            padding-left: 20px;
            position: relative;
            &:before {
                display: block;
                content: "";
                position: absolute;
                left: 0;
                top: 10px;
                height: 8px;
                width: 8px;
                border-radius: 100%;
                margin-right: 10px;
                background-color: ${EditorTheme.colors.black};
            }
        }
    }
`;

const headings = css`
    h1 {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 10px;

        @media (max-width: 478px) {
            font-size: 24px;
            line-height: 30px;
        }
    }

    h2 {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 15px;

        @media (max-width: 478px) {
            font-size: 22px;
            line-height: 28px;
        }
    }

    h3 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 0;

        @media (max-width: 478px) {
            font-size: 20px;
            line-height: 26px;
        }
    }

    h4 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 0;
    }

    h5 {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 0;
    }

    h6 {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0;
    }

    p {
        margin-bottom: 0;
    }
`;

export const GlobalStyles = css`
    ${listStyles}
    ${headings}
`;
