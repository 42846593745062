import {
    Buttons,
    Carousel,
    Container,
    Embed,
    Form,
    Image,
    Map,
    MenuItems,
    Products,
    Text,
    Video,
    YouTube,
} from "@peracto/peracto-blocks";

export default {
    plugins: {
        content: [
            Text,
            Image,
            Video,
            YouTube,
            Embed,
            Map,
            Form,
            Buttons,
            MenuItems,
            Products,
            Carousel,
        ],
        layout: [Container],
    },
};
